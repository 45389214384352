/* eslint-disable no-unused-vars */
import React, { useState } from "react";

import targetCityDetails from "./targetCityDetails";
import waitForMs from "../../helpers/waitForMs";
import worldwideLocalities from "../../helpers/worldwideLocalities";

import earthDark from "../../images/earth-dark.png";
import countries from "../../data/ne_110m_admin_0_countries.json";

import "./SmallSearch.scss";
import SectionHeader from "../SectionHeader/SectionHeader";

let round = 0;
let partialY = 0;
let partialX = 0;
let cityFinder_input_placeholder = "";

function inclementCitiesList(localitiesData) {
  const complement = Object.values(localitiesData["NEW LOCALITIES"])
    .filter(c => (typeof c.Lng == "number" && typeof c.Lat == "number"))
    .map(city => ({
      locality_id:city.locality_id,
      name:city["Administrative Data - City Metro Area"],
      sortBy: Math.random()
    })).sort((a,b) => b.sortBy - a.sortBy);

  initialCities.push(...complement);  
}
function searchSections(contentData,language) {
  const content = contentData["New Search"];

  const sections = [...new Set(Object.keys(content).filter(key => key.includes("Section")).map(k => k.split("_").filter((i,idx) => idx <= 1).join("_")))];
  const sectionsContent = sections.map(section => {
    const sectionKey = String(section);

    const sectionContent = {
        key: sectionKey,
        content: {}
    };


    Object.keys(content).filter(k => k.includes(sectionKey)).map(k => k.replace(`${sectionKey}_`,"")).forEach(key => {
        Object.keys(content)
            .filter(item => item.includes(sectionKey) && item.includes(key))
            .forEach(item => sectionContent.content[key] = content[item][language]);

        sectionContent.content.items = [];
        Object.keys(sectionContent.content).filter(
          contentKey => contentKey.includes("item_")
        ).forEach(item =>
          sectionContent.content.items.push(sectionContent.content[item])
        );
    });

    return sectionContent.content;
  });  

  return sectionsContent;
}

function radFromLat(lat) {
  return lat * (Math.PI / 180);
}
function radFromLng(lng) {
  return -lng * (Math.PI / 180);
}
const initialCities = [
  {locality_id:"san_francisco_county_ca", name:"San Francisco County"},
  {locality_id:"san_jose_ca", name:"San Jose"},
  {locality_id:"puebla_greater_metro_area_mx", name:"Puebla Greater Metropolitan Area"},
  {locality_id:"greater_sao_paulo_metropolitan_area_br", name:"Sao Paulo Greater Metropolitan Area"},
  {locality_id:"greater_london_gb", name:"Greater London Metropolitan Area"},
  {locality_id:"catalonia_metropolitan_region_es", name:"Catalonia Greater Metropolitan Area"},
  {locality_id:"milan_it", name:"Milan"},
  // {locality_id:"riyadh_greater_metropolitan_area", name:"Riyadh"},
  {locality_id:"delhi_in", name:"Delhi National Capital Region"},
  {locality_id:"bangkok_th", name:"Bangkok"},
  {locality_id:"singapore_sg", name:"Singapore"},
  {locality_id:"kuala_lumpur_my", name:"Kuala Lumpur"},
  {locality_id:"jakarta_city_id", name:"Jakarta"},
  {locality_id:"greater_osaka_metropolitan_area_jp", name:"Greater Osaka Metropolitan Area"},
  {locality_id:"greater_melbourne_metropolitan_area_au", name:"Melbourne"}
];

let cityFinder_input = null;
function highilightCityOnGlobe(foundCity){
  return new Promise(resolve => {
    GLB
      .ringsData([{
        lat: foundCity.Lat,
        lng: foundCity.Lng
      }])
      .ringColor(() => "rgba(255,255,255,1)")
      .ringMaxRadius(6)
      .ringPropagationSpeed(-1)
      .ringRepeatPeriod(1000);
  
    rotateGlobeToTargetCity(foundCity).then(resolve);
  });
}
function rotateGlobeToTargetCity(foundCity) {
  return new Promise((resolve) => {

    const roundsToComplete = 10;
  
    const currentPositionY = Number(GLB.rotation.y);
    const currentPositionX = Number(GLB.rotation.x);
  
    const nextPositionY = radFromLng(foundCity.Lng);
    const nextPositionX = radFromLat(foundCity.Lat);
  
    const diffY = nextPositionY - currentPositionY;
    const diffX = nextPositionX - currentPositionX;
  
    partialY = diffY / roundsToComplete;
    partialX = diffX / roundsToComplete;
  
    (function checkTravelingStatus(){
      if(round == roundsToComplete) {
        partialY = 0;
        partialX = 0;
  
        round = 0;

        resolve();
      } else {
        round = round + 1;
        requestAnimationFrame(checkTravelingStatus);
      }
    })();
    // const transitionInterval = setInterval(() => {
    //   console.log("roundsPerformed",roundsPerformed);
    //   console.log("nextPosition",[nextPositionX,nextPositionY]);
    //   console.log("GLB.rotation",[GLB.rotation.x,GLB.rotation.y]);
    //   if(
    //     (roundsPerformed == roundsToComplete) ||
    //     (GLB.rotation.y == nextPositionY && GLB.rotation.x == nextPositionX)
    //   ) {
    //     partialY = 0;
    //     partialX = 0;

    //     clearInterval(transitionInterval);
    //     resolve();
    //   }

    //   roundsPerformed = roundsPerformed + 1;
    // },roundsToComplete);
  });
}
function eraseTargetCity(cityFinder_input,delay = 100) {
  return new Promise(resolve => {
    (async function(){
      let eraseCount = 0;
      let letters = cityFinder_input.value.split("");
      const cidadeAlvoLength = cityFinder_input.value.length;
      const adjustedDelay = delay / (cidadeAlvoLength * 0.5);
  
      while(eraseCount < cidadeAlvoLength) {
        await waitForMs(adjustedDelay);
        letters.pop();
        cityFinder_input.value = letters.join("");

        if(eraseCount++ == (cidadeAlvoLength - 1)) {
          resolve();          
        }
      }
    })();
  });
}

function typeTargetCity(cityFinder_input,cidadeAlvo,delay = 100) {
  return new Promise(resolve => {
    (async function(){
      let typeCount = 0;
      const cidadeAlvoLength = cidadeAlvo.length;
      const adjustedDelay = delay / (cidadeAlvoLength * 0.5);

      while(typeCount < cidadeAlvoLength) {
        await waitForMs(adjustedDelay);
        cityFinder_input.value = `${cityFinder_input.value}${cidadeAlvo[typeCount++]}`;

        if(typeCount == (cidadeAlvoLength - 1)) {
          resolve();
        }
      }
    })();
  });
}

async function demoCity(x,initialCities,cityFinder_input,localitiesData,setTargetCity) {
  if(initialCitiesDemoCarouselIsStopped) 
    return;

  if(x == initialCities.length) {
    stopDemoCarousel();
    return;
  }

  const cidadeAlvo = initialCities[x];

  cityFinder(cidadeAlvo.locality_id,setTargetCity,localitiesData);
  await typeTargetCity(cityFinder_input,cidadeAlvo.name,60);
  
  const cityToDemo = localitiesData["NEW LOCALITIES"][cidadeAlvo.locality_id];
  if(cityToDemo) {
    // await highilightCityOnGlobe(cityToDemo);
  
    await waitForMs(6000);
    await eraseTargetCity(cityFinder_input,30);
  
    demoCity(x+1,initialCities,cityFinder_input,localitiesData,setTargetCity);
  } else {
    demoCity(x+1,initialCities,cityFinder_input,localitiesData,setTargetCity);
  }
}

let initialCitiesDemoCarouselIsStopped = false;
function stopDemoCarousel() {
  initialCitiesDemoCarouselIsStopped = true;
  cityFinder_input.placeholder = cityFinder_input_placeholder;
}
let demonstrationStarted = false;
async function demonstrateCitiesSearch(localitiesData,setTargetCity) {
  if(demonstrationStarted)
    return;

  demonstrationStarted = true;

  cityFinder_input = document.querySelector("#cityFinder_input");

  cityFinder_input_placeholder = cityFinder_input.placeholder;
  cityFinder_input.placeholder = "";

  setTimeout(() => inclementCitiesList(localitiesData),1000);

  if(cityFinder_input) {
    demoCity(0,initialCities,cityFinder_input,localitiesData,setTargetCity);
  }
}

let GLB = undefined;
let scene = undefined;
let camera = undefined;
let renderer = undefined;

let isGlobeStickyAlready = false;
function setStickyGlobe() {
  if(isGlobeStickyAlready) 
    return;
  
  try {
    const min = 672;
    const max = 1772;
    
    const globeviz = document.getElementById("globeViz");
   
    window.onscroll = function(){    
      if(window.scrollY >= min && window.scrollY < max)
        globeviz.style.transform = `translateY(${window.scrollY - min}px`;
    };

    isGlobeStickyAlready = true;
  } catch(e){
    isGlobeStickyAlready = false;
  }
}

async function cityFinder(locality_id,setTargetCity,localitiesData) {
  const foundCity = localitiesData["NEW LOCALITIES"][locality_id];

  if(foundCity) {
    // highilightCityOnGlobe(foundCity);
    setTargetCity(foundCity);
    // setStickyGlobe();
  }
}

let globeStarted = false;
function addExternalGLobeScript(path) {
  return new Promise(resolve => {
    const script = document.createElement("script");
    script.src = path;
    script.async = true;
    document.body.appendChild(script);

    setTimeout(resolve,2000);
  }); 
}

let globeAnimationStarted = false;

function inputComponent(content,language,filterDatalist,setFilterDatalist,localitiesData,avoid,setTargetCity) {
  return <>
    <input type="search"
      autoComplete="off"
      name="cityFinder_input"
      id="cityFinder_input"
      placeholder={content.Search.placeholder[language]}
      value={filterDatalist}
      onChange={function(e){
        setFilterDatalist(e.target.value.toLowerCase());
      }}
      onFocus={function(){
        stopDemoCarousel();
        eraseTargetCity(cityFinder_input,15);
      }}
    />

    <div className="hasDatalist">
      <ul className="datalist">
        {
          localitiesData ? 
            Object.values(localitiesData["NEW LOCALITIES"])
              .filter(cd => !avoid.includes(cd.locality_id))
              .filter(cd =>
                  filterDatalist == ""
                  || cd["Administrative Data - City County Metro Area"].toLowerCase().includes(filterDatalist)
                  || cd["Administrative Data - State or Province"].toLowerCase().includes(filterDatalist)
                  || cd["Administrative Data - Region"].toLowerCase().includes(filterDatalist)
                  || cd["Administrative Data - Country"].toLowerCase().includes(filterDatalist)
              )
              .sort((a,b) => b["Population Density Patterns - Available Product"] - a["Population Density Patterns - Available Product"])
              .map((cd,idx) => {
                const term = [
                  cd["Administrative Data - City County Metro Area"],
                  cd["Administrative Data - State or Province"],
                  cd["Administrative Data - Country"],
                  cd["Administrative Data - Region"]
                ].filter(i => i && i.length > 0).join(",");

                return <li key={idx} className="item">
                <a href={`/search/?locality_id=${cd.locality_id}`} target="_blank" rel="noreferrer">
                  {term}
                </a>
              </li>;
              }) : <></>
        }
      </ul>
    </div>
  </>;
}
function Globe({language,content}) {
  const [filterDatalist, setFilterDatalist] = useState(""); // eslint-disable-line
  const [localitiesData, setLocalitiesData] = useState(null); // eslint-disable-line
  const [targetCity, setTargetCity] = useState(null); // eslint-disable-line

  if(!content) 
    return <></>;

  worldwideLocalities.init(setLocalitiesData).then(localitiesData => {
    demonstrateCitiesSearch(localitiesData,setTargetCity);
  });

  const avoid = [
    "Country",
    "Russia",
    "Ukraine",
    "Belarus",
    "Poland",
    "Slovakia",
    "Hungary",
    "Romania",
    "Bulgaria",
    "Turkey",
    "Georgia",
    "Azerbaijan",
    "Kazakhstan"    
  ];

  const contentSearchSections = searchSections(content,language);

  return (
    <section id="SmallSearch">
      <div className="onDesktop">
        <SectionHeader 
          Headline_1={content.Search.Headline_1[language]}
          Headline_2={`${localitiesData && localitiesData["NEW LOCALITIES"] ? Object.keys(localitiesData["NEW LOCALITIES"]).length : ""} ${content.Search.Headline_2[language]}`}
          Button={content.Search.Button[language]}
          ButtonHref={content.Search.Button["Relevent_Link"]}
        />
      </div>        

      <div className="container">
        <article className="citySearch">
          <div className="holder">
            <div className="cityFinder">
              <div className="findComponents">
                <div className="onDesktop">
                  {window.innerWidth > 1000 ? 
                    inputComponent(content,language,filterDatalist,setFilterDatalist,localitiesData,avoid,setTargetCity)
                    : <></>
                  }
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </section>
  );
}

export default Globe;